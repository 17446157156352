import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "../reducers";
import apiMiddleware from "../api/middleware";

export default createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(apiMiddleware, thunk)),
);
