import React from "react";
import ReactDOM from "react-dom";
import Widget from "./layouts/Widget";
import { Provider } from "react-redux";
import store from "./redux/store";

// eslint-disable-next-line no-undef
console.log(`Widget release ${COMMITHASH}`);

if (window && document) {
  const elem = document.getElementById("rwsverkeersinfowidget");
  if (!elem) {
    console.error(
      "De widget wordt ingeladen maar er is geen element met ID 'rwsverkeersinfowidget' gevonden.",
    );
  }
  const config = {};
  window.eov = {};
  window.eov.API = elem.dataset.baseUrl;
  window.eov.Here = {
    ApiKey: elem.dataset.hereApiKey,
  };
  config.baseUrl = elem.dataset.baseUrl;
  config.isWidget = true;
  config.searchOpensInNewWindow = elem.dataset.newWindow === "true";
  config.showRwsText = elem.dataset.showRwsText === "true";

  ReactDOM.render(
    <Provider store={store}>
      <Widget config={config} />
    </Provider>,
    elem,
  );
}
