import axios from "axios";
import Here from "../../api/here";
import endOfDay from "date-fns/endOfDay";
import isToday from "date-fns/isToday";

const actionTypes = {
  LOAD: "route/load",
  LOAD_SUCCESS: "route/load_success",
  LOAD_FAILED: "route/load_failed",
  SELECT_ROUTE: "route/select_route",
  SELECT_QUERY: "route/select_query",
  GEOCODING_ERROR: "route/geocoding_error",
  REMOVE_GEOCODING_ERROR: "route/remove_geocoding_error",
};

const initialState = {
  loading: false,
  loaded: false,
  error: {},
  selectedRoute: 0,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD:
      return {
        ...state,
        loading: true,
        loaded: false,
        selectedRoute: 0,
        error: {},
      };
    case actionTypes.LOAD_SUCCESS:
      return {
        ...state,
        [action.queryId]: {
          items: action.payload,
          retrieved: new Date().getTime(),
        },
        loading: false,
        loaded: true,
        selectedQuery: action.queryId,
        selectedRoute: action.payload.findIndex(
          x =>
            x.FreeFlowTime ===
            Math.min.apply(
              Math,
              action.payload.map(x => x.FreeFlowTime),
            ),
        ),
      };
    case actionTypes.LOAD_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    case actionTypes.SELECT_ROUTE:
      return {
        ...state,
        selectedRoute: action.route,
      };
    case actionTypes.SELECT_QUERY:
      return {
        ...state,
        selectedQuery: action.queryId,
      };
    case actionTypes.GEOCODING_ERROR:
      return {
        ...state,
        loaded: true,
        loading: false,
        error: {
          ...action.fields,
        },
      };
    case actionTypes.REMOVE_GEOCODING_ERROR:
      const { [action.fieldKey]: removed, ...rest } = state.error;
      return {
        ...state,
        error: {
          ...rest,
        },
      };
    default:
      return state;
  }
}

export const geocodeLocation = async query => {
  const url = Here.GeocodeUrl(query);
  try {
    const response = await axios.get(url);
    const {
      data: { items },
    } = response;
    if (items && items.length > 0) {
      const result = items?.[0];
      const {
        position: { lat: Latitude, lng: Longitude },
      } = result;
      return {
        Latitude,
        Longitude,
      };
    }
    return {
      error: "Deze locatie is onbekend. Probeer een andere locatie.",
    };
  } catch (error) {
    return {
      error: error.message,
    };
  }
};

const geocodingStart = () => ({
  type: actionTypes.LOAD,
});

const searchRoute = (payload, queryId) => ({
  types: [actionTypes.LOAD, actionTypes.LOAD_SUCCESS, actionTypes.LOAD_FAILED],
  method: "POST",
  url: `/route/?options=rd,st,ob`,
  payload,
  queryId,
});

export const selectRoute = route => ({
  type: actionTypes.SELECT_ROUTE,
  route,
});

export const selectQuery = queryKey => ({
  type: actionTypes.SELECT_QUERY,
  queryId: queryKey,
});

export const geocodingHasErrors = fields => {
  return {
    type: actionTypes.GEOCODING_ERROR,
    fields,
  };
};

export const removeFormFieldError = fieldKey => {
  return {
    type: actionTypes.REMOVE_GEOCODING_ERROR,
    fieldKey,
  };
};

export const findRoute = (payload, queryId) => async (dispatch, getState) => {
  const { route } = getState();
  if (Object.keys(route).includes(queryId)) {
    dispatch(selectQuery(queryId));
    return;
  }

  dispatch(geocodingStart());
  const from = await geocodeLocation(payload.from);
  const to = await geocodeLocation(payload.to);
  let query = {
    fromPoint: from,
    toPoint: to,
  };

  if (!isToday(payload.date)) {
    query = {
      ...query,
      fromTime: payload.date.toISOString(),
      untilTime: endOfDay(payload.date).toISOString(),
    };
  }

  // validate geocoding
  const geocodeIsValid = obj => !Object.keys(obj ?? {}).includes("error");
  const fields = {
    from,
    to,
  };
  if (
    Object.keys(fields)
      .map(key => fields[key])
      .every(geocodeIsValid)
  ) {
    // if all geolocated items are valid, actually perform serch.
    dispatch(searchRoute(query, queryId));
  } else {
    dispatch(geocodingHasErrors(fields));
  }
};

export const getObstructionsFromSelectedRoute = (store, queryKey) => {
  const {
    route: { loading, loaded, [queryKey]: { items = [] } = {}, selectedRoute, error },
  } = store;
  const route = items.find((_, index) => index === selectedRoute) || {};
  const { obstructions = [] } = route;

  return {
    loading,
    loaded,
    items: obstructions,
    error,
  };
};

export const getRouteSearchResults = store => {
  const {
    route: { selectedQuery, selectedRoute },
  } = store;
  const { items = [] } = store.route[selectedQuery] || {};
  items.forEach((item, index) => {
    item.selected = index === selectedRoute;
    return item;
  });
  return items;
};
