import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { BrowserRouter as Router } from "react-router-dom";
import Search from "../components/Search";
import colors from "../common/colors";
import { fontFamily } from "../common/GlobalStyle";

const GlobalStyle = createGlobalStyle`
  #rwsverkeersinfowidget {
    --eov-font: ${fontFamily};
  }
`;

const WidgetStyle = styled.div`
    font-family: var(--eov-font);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    color: ${colors.gray900};

    *, *::before, *::after {
        box-sizing: border-box;
        font-family: var(--eov-font);
    }
    input, select, button, textarea {
        font-family: var(--eov-font);
    }
    *:not(input):focus {
        outline: 1px dotted ${colors.gray900};
    }
    h1, h2, h3, h4, h5, h6 {
        color: ${colors.logoblauw};
        margin: 0 0 1.1em;
        line-height: 1.3;
    }
    h1 {
        font-size: 31px;
        font-weight: 400;
    }
    h2 {
        font-size: 24px;
    }
    h3 {
        font-size: 20px;
    }
  }
`;

function Widget(props) {
  const { config = {} } = props;
  return (
    <Router>
      <GlobalStyle />
      <WidgetStyle>
        <Search
          route
          target={config.baseUrl}
          showRwsText={config.showRwsText}
          newWindow={config.searchOpensInNewWindow}
        />
      </WidgetStyle>
    </Router>
  );
}

export default Widget;
