import { processPayload } from "../../common/utils";
import parse from "date-fns/parse";
import isPast from "date-fns/isPast";
import isToday from "date-fns/isToday";
import format from "date-fns/format";

const actionTypes = {
  LOAD: "peakexpectation/load",
  LOAD_SUCCESS: "peakexpectation/load_success",
  LOAD_FAILED: "peakexpectation/load_failed",
  LOAD_PAGE: "peakexpectation/load_page",
  LOAD_PAGE_SUCCESS: "peakexpectation/load_page_success",
  LOAD_PAGE_FAILED: "peakexpectation/load_page_failed",
};

const initialState = {
  loading: false,
  loaded: false,
  items: {},
  page: {
    loading: false,
    loaded: false,
  },
  error: {},
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: {},
      };

    case actionTypes.LOAD_SUCCESS:
      return {
        ...state,
        error: {},
        items: {
          ...state.items,
          ...processPayload(action.payload),
        },
        count: action.payload.count,
        loading: false,
        loaded: true,
      };

    case actionTypes.LOAD_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };

    case actionTypes.LOAD_PAGE:
      return {
        ...state,
        page: {
          loading: true,
          loaded: false,
        },
        error: {},
      };

    case actionTypes.LOAD_PAGE_FAILED:
      return {
        ...state,
        page: {
          loading: false,
          loaded: false,
        },
        error: action.error,
      };
    case actionTypes.LOAD_PAGE_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          ...processPayload(action.payload.items[0], "cards"),
        },
        page: {
          loading: false,
          loaded: true,
          ...action.payload.items[0],
        },
      };
    default:
      return state;
  }
}

export const loadPeakExpectationCards = () => ({
  types: [actionTypes.LOAD, actionTypes.LOAD_SUCCESS, actionTypes.LOAD_FAILED],
  method: "GET",
  url: `/peakexpectations/`,
});

export const loadPeakExpectationPage = () => ({
  types: [actionTypes.LOAD_PAGE, actionTypes.LOAD_PAGE_SUCCESS, actionTypes.LOAD_PAGE_FAILED],
  method: "GET",
  url: `/api/v2/pages/?type=website.PeakExpectationPage&fields=youtube_embed,disclaimer,cards,seo_title,search_description`,
});

export const getPeakExpectation = state => {
  const { peakexpectation: { items, loading, loaded, error, count } = {} } = state;
  const visibleItems = Object.keys(items)
    .map(key => items[key])
    .sort(sortPeakExpectations);

  const cards = visibleItems.filter(filterStalePeakExpectationCards);

  return {
    items: cards.slice(0, 2),
    loading,
    loaded,
    error,
    count,
  };
};

const filterStalePeakExpectationCards = obj => {
  const date = parse(obj.date, "yyyy-MM-dd", new Date());
  if (isToday(date)) {
    const daypart = format(new Date(), "a");
    if (daypart === "PM") {
      return obj.daypart === "PM";
    }
    return true;
  }
  if (isPast(date)) {
    return false;
  }

  return true;
};

const sortPeakExpectations = (a, b) => {
  const comparisonResult = a.date.localeCompare(b.date, "nl", { sensitivity: "base" });
  if (comparisonResult === 0) {
    return a.daypart.localeCompare(b.daypart, "nl", { sensitivity: "base" });
  }
  return comparisonResult;
};

export const getPeakExpectationPage = state => {
  const { peakexpectation: { items, page, error = {} } = {} } = state;

  const { loading, loaded } = page;

  const shouldLoad =
    !loading && !loaded && (Object.keys(error).length === 0 || error.length === 0);

  const cards = Object.keys(items)
    .map(key => items[key])
    .sort(sortPeakExpectations)
    .filter(filterStalePeakExpectationCards);

  return {
    items: cards,
    loading,
    shouldLoad,
    page,
  };
};
