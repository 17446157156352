import parseISO from "date-fns/parseISO";
import isSameDay from "date-fns/isSameDay";
import isWithinInterval from "date-fns/isWithinInterval";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";

const actionTypes = {
  LOAD: "rwsroads/load",
  LOAD_SUCCESS: "rwsroads/load_success",
  LOAD_FAILED: "rwsroads/load_failed",
  LOAD_ITEM: "rwsroads/load_item",
  LOAD_ITEM_SUCCESS: "rwsroads/load_item_success",
  LOAD_ITEM_FAILED: "rwsroads/load_item_failed",
  SET_DATE: "rwsroads/set_filter_date",
};

const addObstructions = (items, payload) => {
  if (payload.length === 0) return items;
  const { roadNumber } = payload[0];
  const index = items.findIndex(item => item.number === roadNumber);
  if (index === -1) {
    return [
      ...items,
      {
        number: roadNumber,
        name: "",
        isrws: true,
        obstructions: payload,
      },
    ];
  }
  return [
    ...items.slice(0, index),
    {
      ...items[index],
      obstructions: payload,
    },
    ...items.slice(index + 1),
  ];
};

export function reducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.LOAD:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: {},
      };
    case actionTypes.LOAD_SUCCESS:
      return {
        ...state,
        error: {},
        items: action.payload,
        loading: false,
        loaded: true,
      };
    case actionTypes.LOAD_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    case actionTypes.LOAD_ITEM:
      return {
        ...state,
        loadingRoad: true,
        loadedRoad: false,
        error: {},
      };
    case actionTypes.LOAD_ITEM_SUCCESS:
      return {
        ...state,
        loadingRoad: false,
        loadedRoad: true,
        items: addObstructions(state.items, action.payload),
        selected: action.selectedRoad,
        date: action.date,
      };
    case actionTypes.LOAD_ITEM_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.SET_DATE:
      return {
        ...state,
        date: action.date,
      };
    default:
      return state;
  }
}

export const loadRoads = () => ({
  types: [actionTypes.LOAD, actionTypes.LOAD_SUCCESS, actionTypes.LOAD_FAILED],
  method: "GET",
  url: `/api/roads/`,
});

export const getRoads = state => {
  const {
    roads: { items = [], loading, loaded },
  } = state;

  return {
    roads: items.map(x => x.number),
    // only load items when not loaded, not loading and no items are there yet.
    shouldLoad: Boolean(!loading && items.length === 0 && !loaded),
  };
};

export const getRoadObstructionMarkers = state => {
  const {
    roads: { items = [], selected },
  } = state;

  const selectedRoad = items.find(item => item.number === selected);
  const { obstructions = [] } = selectedRoad || {};
  return obstructions;
};

export const loadRoadObstructions = payload => ({
  types: [actionTypes.LOAD_ITEM, actionTypes.LOAD_ITEM_SUCCESS, actionTypes.LOAD_ITEM_FAILED],
  method: "GET",
  url: `/obstructions/road/${payload.road}/`,
  selectedRoad: payload.road,
  date: payload.date,
});

export const getObstructionsOnRoad = store => {
  const {
    roads: { items = [], loadingRoad, loadedRoad, selected, date },
  } = store;
  const roadObject = items.find(x => x.number === selected);
  const { obstructions = [] } = roadObject || {};
  return {
    loading: loadingRoad,
    loaded: loadedRoad,
    items: obstructions.filter(obstruction => {
      if (obstruction.timeStart && obstruction.timeEnd) {
        const start = parseISO(obstruction.timeStart);
        const end = parseISO(obstruction.timeEnd);
        return isWithinInterval(date, {
          start: startOfDay(start),
          end: endOfDay(end),
        });
      }
      if (obstruction.timeStart && !obstruction.timeEnd) {
        return isSameDay(date, parseISO(obstruction.timeStart));
      }
      return false;
    }),
  };
};

export const setDate = date => ({
  type: actionTypes.SET_DATE,
  date,
});
